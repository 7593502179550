<template>
  <div>
    <b-card>
      <router-link :to="to">
        <h4 class="text-danger mb-4">نظرات جدید</h4>
      </router-link>

      <b-table responsive striped outlined hover :fields="table_fields" :items="items">
        <template v-slot:cell(created_at)="data">
          <p v-if="data.item.created_at">{{ data.item.created_at | persianDate}}</p>
        </template>

        <template v-slot:cell(status)="data">
          <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
          <span class="badge badge-light" v-else>غیرفعال</span>
        </template>
        <template v-slot:cell(product_id)="data">
          {{ data.item.product.title }}
        </template>
        <template v-slot:cell(customer_id)="data">
          {{ data.item.customer.first_name }} {{ data.item.customer.last_name }}
        </template>
        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editComments(data.index)"><i class="fa fa-edit"></i></button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>
    <b-modal id="edit-comments" title="ویرایش نظر" hide-footer size="xl">
      <div v-if="edit.id">
        <form @submit.prevent="commentEdit" id="comment-edit">
          <div class="row">
            <div class="col-md-6">
              <b-form-group label="نظر" label-for="comment">
                <b-form-textarea rows="6" id="comment" name="comment" v-model="edit.comment" :disabled="disabled">
                </b-form-textarea>
              </b-form-group>
              <div class="form-group">
                <label class="custom-switch">
                  <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">وضعیت</span>
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <b-form-group label="پاسخ" label-for="response">
                <b-form-textarea rows="6" id="response" name="response" v-model="edit.response" :disabled="disabled"></b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <div class="clearfix">
            <button type="submit" class="btn btn-primary float-right" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="put" name="_method">

        </form>
      </div>
    </b-modal>
  </div>

</template>
<script>
  export default {
    props: ['to', 'items'],
    data() {
      return {
        url: '/api/admin/comments',
        table_fields: [{
            key: 'comment',
            label: 'نظر'
          },
          {
            key: 'product_id',
            label: 'محصول'
          },
          {
            key: 'customer_id',
            label: 'مشتری'
          },
          {
            key: 'status',
            label: 'وضعیت'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          {
            key: 'edit',
            label: 'ویرایش '
          },
        ],
        edit: {},
        disabled: false
      }
    },
    filters: {
      persianDate(date) {
        return window.moment(date).format('HH:mm jYYYY/jMM/jDD ')
      }
    },
    methods: {
      editComments(index) {
        let item = window.clone(this.items[index])
        item.index = index;
        this.edit = item;
        this.$root.$emit('bv::show::modal', 'edit-comments')
      },
      commentEdit() {
        this.disabled = true
        let form = document.getElementById('comment-edit')
        let formData = new FormData(form)
        this.$axios.post(this.$root.baseUrl + this.url + `/${this.edit.id}`, formData)
          .then(response => {
            this.$root.$delete(this.items, this.edit.index)
            this.$root.$emit('bv::hide::modal', 'edit-comments')
            this.disabled = false
          })
          .catch(error => {
            this.$root.error_notification(error)
            this.disabled = false
          })
      }
    },
    created() {
     
    }
  }
</script>