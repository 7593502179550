<template>
    
        <div class="col-md-3">
            <router-link :to="to">
                <div class="info-box">
                    <span :class="'info-box-icon bg-' + color"><i :class="'text-white ' + icon "></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">{{ info }}</span>
                        <a href="#" class="info-box-number">{{ count }}</a>
                    </div>
                </div>
            </router-link>
        </div>
   
</template>
<script>
export default {
  props: ['to', 'color', 'icon', 'info', 'count']
}
</script>
